

import { Vue, Component } from 'vue-property-decorator'
import { WorkerDetail } from '@/types/onlineStatistics'
import { Page } from '@/types/common'

@Component
export default class OnlineStatisticsDetail extends Vue {
  page = 1
  size = 10
  total = 0
  loading = false
  tableData: WorkerDetail[] = []

  searchForm = {
    workerName: '',
    ruleState: ''
  }

  created () {
    this.loadData()
  }

  get projectId () {
    return this.$route.query.projectId
  }

  get dateTime () {
    return this.$route.query.dateTime
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<WorkerDetail>>(this.$apis.bracelet.selectWorkerDateByPage, {
      page: this.page,
      size: this.size,
      projectId: this.projectId,
      dateTime: this.dateTime,
      ...this.searchForm
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }
}
